import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/-ifC1lJqGFM">
        <SEO title="Reality on the Wrong Side - Crossing Rivers" />
    </Layout>
)

export default SermonPost
